import React from "react"
import styled from "styled-components"

import Image from "../image"

const StyledAbout = styled.div`
  padding-top: 40px;

  .layout {
    max-width: 1100px;
    padding: 40px;
  }

  .section-one {
    display: flex;
    align-items: center;

    .section-left {
      width: 40%;
      margin-right: 10%;
    }

    .section-right {
      width: 50%;
    }
  }

  .section-two {
    display: flex;
    align-items: center;
    padding-top: 50px;

    .section-left {
      width: 50%;
      padding-bottom: 50px;
    }

    .section-right {
      width: 40%;
      margin-left: 10%;
    }
  }

  .filosofi-logo {
    background: #f7f7f7;
    padding-top: 50px;
    padding-bottom: 65px;
    margin-bottom: 20px;

    h2 {
      text-align: center;
      padding: 10px;
    }

    hr {
      width: 60px;
      border-width: 3px;
      border-color: #feef3c;
      padding-bottom: 15px;
    }

    .filosofi-boxes {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .one-box {
        padding: 30px;
        background: #fff;
        box-shadow: 0 10px 29px 0 rgb(68 88 144 / 10%);
        border-radius: 15px;
        text-align: center;
        border-bottom: 3px solid #fff;
        width: 350px;
        margin-left: 1.5%;
        margin-right: 1.5%;
        margin-top: 10px;
        margin-bottom: 10px;
        transition: transform 0.2s;
      }

      .judul {
        font-size: 1.1em;
        font-weight: bold;
        padding-bottom: 10px;
      }
    }

    .filosofi-boxes {
      .one-box:hover {
        transform: scale(1.05);
      }
    }
  }

  @media only screen and (max-width: 991.98px) {
    padding-top: 20px;

    .layout {
      max-width: 600px;
      padding: 22px;
    }

    .section-one {
      flex-wrap: wrap;

      .section-left {
        max-width: 400px;
        width: 100%;
        margin-right: 0;
        margin: auto;
        margin-bottom: 10px;
      }

      .section-right {
        width: 100%;
      }
    }

    .section-two {
      flex-wrap: wrap;
      padding-top: 20px;

      .section-left {
        width: 100%;
        padding-bottom: 0;
        margin-bottom: 10px;
      }

      .section-right {
        max-width: 400px;
        width: 100%;
        margin-left: 0;
        margin: auto;
      }
    }
  }

  @media only screen and (max-width: 500px) {
    .filosofi-logo .filosofi-boxes .one-box {
      width: 80%;
    }
  }
`

export default function AboutComponent() {
  return (
    <StyledAbout>
      <div className="layout">
        <div className="section-one">
          <div className="section-left">
            <Image imgName="tentang-hobi.png" />
          </div>
          <div className="section-right">
            <h2>Sekilas Tentang Purwalenta</h2>
            <p>
              Purwalenta adalah sebuah start-up bidang pendidikan berbasis
              teknologi dengan kekhususan pemberian layanan pendidikan melalui
              metode dalam jaringan (daring) dan luar jaringan (luring).
              Pemberian layanan ditujukan kepada masyarakat dalam berbagai
              jenjang kami biasa menyebutnya dengan panggilan “Kawanlenta”.
              Layanan pendidikan dilangsungkan dengan melibatkan setiap orang
              yang memiliki kompetensi untuk memberikan pengajaran, pelatihan,
              atau pendidikan kepada “Kawanlenta”, kami menyebutnya sebagai
              “Tutorlenta”.
            </p>
            <p>
              Didirikan pada 23 April 2019, Purwalenta hadir untuk melayani
              masyarakat dalam mengembangkan dan mengekspresikan diri ditengah
              masifnya inovasi dan teknologi global. Kami menggunakan teknologi
              saat ini untuk memfasilitasi dan membekali masyarakat dengan
              keterampilan yang dibutuhkan agar menjadi sukses dan hidup bahagia
              pada abad ke 21.
            </p>
          </div>
        </div>
        <div className="section-two">
          <div className="section-left">
            <h2>Visi Purwalenta</h2>
            <div>
              Menjadi gerakan yang
              <br />
              berpengaruh untuk
              <br />
              meningkatkan layanan
              <br />
              pendidikan kepada masyarakat
              <br />
              melalui metode daring dan luring.
            </div>
          </div>
          <div className="section-right">
            <Image imgName="tentang-visi.png" />
          </div>
        </div>
      </div>
      <div className="filosofi-logo">
        <h2>Latar Belakang Nama Purwalenta</h2>
        <hr />
        <div className="filosofi-boxes">
          <div className="one-box">
            <div className="judul">1. Deskripsi</div>
            <div className="deskripsi">
              Purwalenta terdiri dari dua kata yaitu purwa dan talenta.
            </div>
          </div>
          <div className="one-box">
            <div className="judul">2. Penjelasan</div>
            <div className="deskripsi">
              Menurut KBBI, purwa berarti permulaan dan talenta berarti bakat.
              Purwalenta merupakan sebuah tempat atau wadah sebagai permulaan
              bagi bakat individu.
            </div>
          </div>
          <div className="one-box">
            <div className="judul">3. Dasar Filosofis</div>
            <div className="deskripsi">
              Nama keIndonesian yang mudah didengar serta mudah dimengerti.
            </div>
          </div>
        </div>
      </div>
    </StyledAbout>
  )
}
